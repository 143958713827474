



















































import { Component, Prop, Vue } from 'vue-property-decorator'
import { VueEditor } from 'vue2-editor'
import TextInput from '@/app/ui/components/TextInput/index.vue'
import CaretDownIcon from '@/app/ui/assets/caret_down_icon.vue'
import MinCircleIcon from '@/app/ui/assets/min_circle_icon.vue'
import RepostIcon from '@/app/ui/assets/repost_icon.vue'
import DragIcon from '@/app/ui/assets/drag_icon.vue'
import StarIcon from '@/app/ui/assets/icon_star.vue'
import DragDisabledIcon from '@/app/ui/assets/icon_drag_disabled.vue'

@Component({
  inheritAttrs: false,
  components: {
    VueEditor,
    CaretDownIcon,
    DragIcon,
    TextInput,
    MinCircleIcon,
    RepostIcon,
    StarIcon,
    DragDisabledIcon,
  },
})
export default class QnAItemDetail extends Vue {
  @Prop({ required: true }) private index!: number
  @Prop({ required: true }) private order!: number
  @Prop({ required: true }) private question!: string
  @Prop({ required: true }) private answer!: string
  @Prop({ default: 0 }) private idQuestion!: number
  @Prop({ required: true }) private isPublished!: boolean
  @Prop({ default: true }) private isDefaultOpen!: boolean
  @Prop({ default: false }) private isStar!: boolean
  @Prop({ default: false }) private isDisabled!: boolean



  isShowAnswer = this.isDefaultOpen

  private onToggleAnswer() {
    this.isShowAnswer = !this.isShowAnswer
  }
}
