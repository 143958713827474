





































































































































































































































































































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import dayjs from 'dayjs'
import Draggable from 'vuedraggable'
import controller from '@/app/ui/controllers/FAQController'
import TextInput from '@/app/ui/components/TextInput/index.vue'
import Badge from '@/app/ui/components/Badge/index.vue'
import LoadingOverlay from '@/app/ui/components/LoadingOverlay/index.vue'
import Modal from '@/app/ui/components/Modal/index.vue'
import LeaveModal from '@/app/ui/components/LeaveModal/index.vue'
import Toggle from '@/app/ui/components/Toggle/index.vue'
import Button from '@/app/ui/components/Button/index.vue'
import AddIcon from '@/app/ui/assets/add_icon.vue'
import EditIcon from '@/app/ui/assets/edit_icon.vue'
import QnAItemDetail from '../components/QnAItemDetail/index.vue'
import QnAItemSubDetail from '../components/QnAItemSubDetail/index.vue'
import EmptyState from '@/app/ui/components/EmptyState/EmptyState.vue'
import ModalStarredQuestion from '../components/Modals/StarredQuestion.vue'
import ModalUnstarredQuestion from '../components/Modals/UnstarredQuestion.vue'
import ModalUploadIconRequired from '../components/Modals/UploadIconRequired.vue'
import { EventBus, EventBusConstants, Utils } from '@/app/infrastructures/misc'
import { FAQ, QnA, QnASubCategory } from '@/domain/entities/FAQ'
import { NavigationGuardNext, Route } from 'vue-router'

@Component({
  components: {
    Draggable,
    TextInput,
    LoadingOverlay,
    Modal,
    LeaveModal,
    QnAItemDetail,
    QnAItemSubDetail,
    AddIcon,
    EditIcon,
    EmptyState,
    ModalStarredQuestion,
    ModalUnstarredQuestion,
    ModalUploadIconRequired,
    Toggle,
    Button,
    Badge,
  },
})
export default class FAQDetailPage extends Vue {
  controller = controller
  questionsData = [] as QnA[]
  subCategoryList = [] as QnASubCategory[]
  isWithSubCategory = false
  selectedFAQId = 0
  unpostConfirmationModal = false
  repostConfirmationModal = false
  statusConfirmationModal = false
  updateConfirmationModal = false
  uploadIconRequiredModal = false
  starQuestionModal = {
    show: false,
    idQuestion: 0,
  }
  unstarQuestionModal = {
    show: false,
    idQuestion: 0,
  }
  leavePromptModal = false
  nextNavigate = {} as NavigationGuardNext
  successModal = false
  successModalMsg = {
    title: '',
    body: '',
  }
  isGuest = Utils.isGuest()
  parameters = {
    tab: this.controller.tabOptions.PUBLISHED,
    search: '',
  }
  isEditing = false
  isSubmiting = false

  beforeMount(): void {
    window.addEventListener('beforeunload', this.warnClosing)
    this.$once('hook:beforeDestroy', () => {
      window.removeEventListener('beforeunload', this.warnClosing)
    })
  }

  beforeRouteLeave(to: Route, from: Route, next: NavigationGuardNext): void {
    if (this.isEditing && !this.isSubmiting) {
      this.nextNavigate = next
      this.leavePromptModal = true
      if (this.leavePromptModal) {
        return
      }
    }
    next()
  }

  created(): void {
    if (this.$route.query instanceof Object) {
      const queries = this.$route.query as Record<string, never>
      this.parameters = {
        tab:
          queries.tab === this.controller.tabOptions.UNPUBLISHED
            ? this.controller.tabOptions.UNPUBLISHED
            : this.controller.tabOptions.PUBLISHED,
        search: queries.search || '',
      }
    }

    EventBus.$on(EventBusConstants.UNPOST_FAQ_SUCCESS, () => {
      this.successModalMsg = {
        title: 'Answer Unposted',
        body: 'Your answer successfuly unposted',
      }
      this.successModal = true
    })

    EventBus.$on(EventBusConstants.REPOST_FAQ_SUCCESS, () => {
      this.successModalMsg = {
        title: 'Answer Repost',
        body: 'Your answer successfuly reposted',
      }
      this.successModal = true
    })

    EventBus.$on(EventBusConstants.UPDATE_FAQ_STATUS_SUCCESS, () => {
      this.successModalMsg = {
        title: 'FAQ Status Changed',
        body: 'Your FAQ status has been changed',
      }
      this.successModal = true
    })

    EventBus.$on(EventBusConstants.DETAIL_FAQ_SUCCESS, () => {
      this.successModalMsg = {
        title: 'FAQ Saved',
        body: 'New information has been saved',
      }
      this.isSubmiting = false
      this.isEditing = false
      this.successModal = true
    })

    EventBus.$on(EventBusConstants.STAR_QUESTION_SUCCESS, () => {
      this.successModalMsg = {
        title: 'Star Question Saved',
        body: 'New information has been saved',
      }
      this.isSubmiting = false
      this.isEditing = false
      this.successModal = true
    })

    EventBus.$on(EventBusConstants.UNSTAR_QUESTION_SUCCESS, () => {
      this.successModalMsg = {
        title: 'Unstar Question Saved',
        body: 'New information has been saved',
      }
      this.isSubmiting = false
      this.isEditing = false
      this.successModal = true
    })

    this.fetchDetail()
  }

  @Watch('params')
  onParamsChanged(val: Record<string, never>): void {
    this.$router.replace({
      query: { ...val },
    })
  }

  @Watch('controller.faqDetail')
  onQuestionDataChanged(faq: FAQ): void {
    this.questionsData = faq.questionsData as QnA[]
    this.subCategoryList = faq.subCategoryList as QnASubCategory[]
    this.isWithSubCategory = faq.withSubCategory as boolean
  }

  get isDraggable(): boolean {
    return !this.parameters.search
  }

  get params() {
    return {
      ...this.parameters,
      tab: this.parameters.tab || this.controller.tabOptions.PUBLISHED,
      search: this.parameters.search || '',
    }
  }

  get publishedData() {
    const keyword = this.parameters.search
    if (this.isWithSubCategory) {
      return this.subCategoryList.map(item => {
        const items = item.data?.filter(
          (unit) =>
            unit.question
              ?.toLowerCase()
              ?.includes(keyword.toLowerCase()))
          .filter(q => !q.isDraft)
        if (items && items.length > 0) {
          return { ...item, data: items }
        }
      }).filter(sub => sub)
    } else {
      return this.questionsData
        .filter(
          (item) =>
            item.question
              ?.toLowerCase()
              ?.includes(keyword.toLowerCase())
        )
        .filter((item) => !item.isDraft)
    }
  }

  get unpublishedData() {
    const keyword = this.parameters.search
    if (this.isWithSubCategory) {
      return this.subCategoryList.map(item => {
        const items = item.data?.filter(
          (unit) =>
            unit.question
              ?.toLowerCase()
              ?.includes(keyword.toLowerCase()))
          .filter(q => q.isDraft)
        if (items && items.length > 0) {
          return { ...item, data: items }
        }
      }).filter(sub => sub)
    } else {
      return this.questionsData
        .filter(
          (item) =>
            item.question
              ?.toLowerCase()
              ?.includes(keyword.toLowerCase()) ||
            item.answer
              ?.toLowerCase()
              ?.includes(this.parameters.search.toLowerCase())
        )
        .filter((item) => item.isDraft)
    }
  }

  private indexQuestion(order: number) {
    return this.subCategoryList.findIndex(i => i.order === order)
  }

  private numberOfPosted(isPosted: boolean): number {
    if (this.isWithSubCategory) {
      let rows = [] as QnA[]
      this.subCategoryList.forEach(item => {
        if (isPosted) {
          const items = item.data?.filter(unit => !unit.isDraft)
          if (items && items.length > 0) rows = rows.concat(items)
        } else {
          const items = item.data?.filter(unit => unit.isDraft)
          if (items && items.length > 0) rows = rows.concat(items)
        }
      })
      return rows.length || 0
    } else {
      if (isPosted) {
        return this.publishedData.length || 0
      } else {
        return this.unpublishedData.length || 0
      }
    }
  }

  private onLeavePage() {
    if (this.nextNavigate) {
      this.leavePromptModal = false
      this.nextNavigate()
    }
  }

  private warnClosing(event: {
    preventDefault: () => void
    returnValue: string
  }) {
    if (!this.isEditing || this.isSubmiting) return
    event.preventDefault()
    event.returnValue = ''
  }

  private onClickStar(id: number, isStar: boolean) {
    if (isStar) {
      this.unstarQuestionModal = {
        show: true,
        idQuestion: id,
      }
    } else {
      this.starQuestionModal = {
        show: true,
        idQuestion: id,
      }
    }
  }

  private onActionStar() {
    const { idQuestion } = this.starQuestionModal
    this.starQuestionModal.show = false
    this.controller.starUnstarQuestion({
      idQuestion: idQuestion,
      action: 'star'
    })
  }

  private onActionUnstar() {
    const { idQuestion } = this.unstarQuestionModal
    this.unstarQuestionModal.show = false
    this.controller.starUnstarQuestion({
      idQuestion: idQuestion,
      action: 'unstar'
    })
  }

  private onDraggedSubCategory() {
    this.subCategoryList = this.subCategoryList.map((item, idx) => {
      item.order = idx + 1
      return item
    })
  }

  private onDragged() {
    this.isEditing = true
    this.reSortOrder(this.isWithSubCategory)
  }

  private onUpdateFAQStatus(isActive: boolean) {
    this.statusConfirmationModal = false

    this.controller.updateFAQCategoryStatus({
      categoryId: this.$route.params.id,
      isActive: isActive,
    })
  }

  private onUpdateFAQDraftStatus(isDraft: boolean) {
    this.unpostConfirmationModal = false
    this.repostConfirmationModal = false

    this.controller.updateFAQDraftStatus({
      categoryId: this.$route.params.id,
      faqId: this.selectedFAQId,
      isDraft: isDraft,
    })
  }

  private onUpdate() {
    this.updateConfirmationModal = false
    this.isSubmiting = true
    if (!controller.faqDetail.iconImage) {
      this.uploadIconRequiredModal = true
    } else {
      controller.updateFAQ({
        categoryId: this.$route.params.id,
        categoryName: this.controller.faqDetail.categoryName as string,
        isActive: this.controller.faqDetail.isActive as boolean,
        data: this.questionsData,
        withSubCategory: this.controller.faqDetail.withSubCategory as boolean,
        subCategoryList: this.subCategoryList,
        iconImage: this.controller.faqDetail.iconImage as string
      })
    }
  }

  private reSortOrder(isSubCategory?: boolean) {
    if (isSubCategory) {
      this.subCategoryList.forEach((item, index) => {
        item.data?.forEach((unit, idx) => {
          const question = this.subCategoryList[index].data
          if (unit.isDraft && question) {
            question.push(question.splice(idx, 1)[0])
          }
        })
      })
      this.subCategoryList = this.subCategoryList.map((item) => {
        const result = item.data?.map((unit, idx) => {
          return { ...unit, order: idx + 1 }
        })
        return { ...item, data: result }
      })
    } else {
      for (let i = 0; i < this.questionsData.length; i++) {
        if (this.questionsData[i].isDraft) {
          this.questionsData.push(this.questionsData.splice(i, 1)[0])
        }
      }
      this.questionsData = this.questionsData.map((item, idx) => {
        item.order = idx + 1
        return item
      })
    }
  }

  private onCloseSuccessModal() {
    this.successModal = false
    this.fetchDetail()
  }

  private fetchDetail() {
    controller.getFAQDetail(this.$route.params.id)
  }

  private formatDate(date: string | undefined) {
    return date
      ? dayjs(date).format('DD MMMM YYYY, HH:mm [WIB]')
      : '-'
  }

  beforeDestroy(): void {
    EventBus.$off(EventBusConstants.UNPOST_FAQ_SUCCESS)
    EventBus.$off(EventBusConstants.REPOST_FAQ_SUCCESS)
    EventBus.$off(EventBusConstants.UPDATE_FAQ_STATUS_SUCCESS)
    EventBus.$off(EventBusConstants.DETAIL_FAQ_SUCCESS)
    EventBus.$off(EventBusConstants.STAR_QUESTION_SUCCESS)
    EventBus.$off(EventBusConstants.UNSTAR_QUESTION_SUCCESS)
  }
}
