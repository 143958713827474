
























import { Component, Prop, Vue } from 'vue-property-decorator'
import Modal from '@/app/ui/components/Modal/index.vue'
import Button from '@/app/ui/components/Button/index.vue'
import BadgeFailedIcon from '@/app/ui/assets/badge_failed_icon.vue'

@Component({
  components: {
    Modal,
    Button,
    BadgeFailedIcon,
  },
})

export default class ModalUploadIconRequired extends Vue {
  @Prop({ default: false }) private visible!: boolean
}
