















































import { Component, Prop, Vue } from 'vue-property-decorator'
import { VueEditor } from 'vue2-editor'
import Draggable from 'vuedraggable'
import TextInput from '@/app/ui/components/TextInput/index.vue'
import QnAItemDetail from '../QnAItemDetail/index.vue'
import CaretDownIcon from '@/app/ui/assets/caret_down_icon.vue'
import MinCircleIcon from '@/app/ui/assets/min_circle_icon.vue'
import RepostIcon from '@/app/ui/assets/repost_icon.vue'
import DragIcon from '@/app/ui/assets/drag_icon.vue'
import StarIcon from '@/app/ui/assets/icon_star.vue'
import DragDisabledIcon from '@/app/ui/assets/icon_drag_disabled.vue'
import { QnA } from '@/domain/entities/FAQ'

@Component({
  inheritAttrs: false,
  components: {
    VueEditor,
    Draggable,
    QnAItemDetail,
    CaretDownIcon,
    DragIcon,
    TextInput,
    MinCircleIcon,
    RepostIcon,
    StarIcon,
    DragDisabledIcon,
  },
})
export default class QnAItemSubDetail extends Vue {
  @Prop({ default: false }) private isDisabled!: boolean
  @Prop({ default: true }) private isDefaultOpen!: boolean
  @Prop({ default: true }) private isPublished!: boolean
  @Prop({ default: 0 }) private index!: number
  @Prop({ default: '' }) private subCategoryName!: string
  @Prop({ default: [] }) private questions!: QnA[]
  @Prop({ default: [] }) private valueQuestion!: QnA[]


  isExpand = this.isDefaultOpen

  private onToggleAnswer() {
    this.isExpand = !this.isExpand
  }

  private onChange() {
    this.$emit('drag-question', this.valueQuestion, this.index)
  }
}
